import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class EventDetectorService {
    detectKeyPress(): Observable<KeyboardEvent> {
        return fromEvent<KeyboardEvent>(document, 'keypress');
    }

    detectResize(): Observable<KeyboardEvent> {
        return fromEvent<KeyboardEvent>(window, 'resize');
    }

    detectBeforeunload(): Observable<BeforeUnloadEvent> {
        return fromEvent<BeforeUnloadEvent>(window, 'beforeunload');
    }

    online(): Observable<Event> {
        return fromEvent<Event>(window, 'online');
    }

    offline(): Observable<Event> {
        return fromEvent<Event>(window, 'offline');
    }

    visibilitychange(): Observable<Event> {
        return fromEvent<Event>(document, 'visibilitychange');
    }
}
